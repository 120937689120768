const initialState = { reseller: [], resellerDetail: {} };

function resellerReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_RESELLER":
      return {
        ...state,
        reseller: action.payload,
      };
    case "SET_ONE_RESELLER":
      return {
        ...state,
        resellerDetail: action.payload,
      };
    case "ADD_RESELLER":
      return {
        ...state,
        reseller: state.reseller.concat(action.payload),
      };
    case "UPDATE_RESELLER":
      return {
        ...state,
        reseller: state.reseller
          .filter((data) => data.timeInNumber !== action.payload.timeInNumber)
          .concat(action.payload),
        resellerDetail: action.payload,
      };
    case "DELETE_RESELLER":
      return {
        ...state,
        reseller: state.reseller.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default resellerReducer;
