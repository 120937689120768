const initialState = { visitorHistory: [], visitor: [] };

function visitorHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_VISITORHISTORY":
      return {
        ...state,
        visitorHistory: action.payload,
      };
    case "SET_VISITORHISTORY_UPDATE":
      return {
        ...state,
        visitorHistory: action.payload,
      };

    case "SET_VISITOR":
      return {
        ...state,
        visitor: action.payload,
      };
    default:
      return state;
  }
}

export default visitorHistoryReducer;
