const initialState = { english: [], englishUpdate: {}, englishForm: {} };

function englishReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ENGLISH":
      return {
        ...state,
        english: action.payload,
      };
    case "UPDATE_ENGLISH":
      return {
        ...state,
        englishForm: action.payload,
      };
    case "SET_ENGLISH_UPDATE":
      return {
        ...state,
        englishUpdate: action.payload,
      };
    case "SET_FORM_ENGLISH":
      return {
        ...state,
        englishForm: action.payload,
      };
    default:
      return state;
  }
}

export default englishReducer;
