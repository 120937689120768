const initialState = { wholesaler: [], wholesalerDetail: {} };

function wholesalerReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_WHOLESALER":
      return {
        ...state,
        wholesaler: action.payload,
      };
    case "SET_ONE_WHOLESALER":
      return {
        ...state,
        wholesalerDetail: action.payload,
      };
    case "ADD_WHOLESALER":
      return {
        ...state,
        wholesaler: state.wholesaler.concat(action.payload),
      };
    case "UPDATE_WHOLESALER":
      return {
        ...state,
        wholesaler: state.wholesaler
          .filter((data) => data.timeInNumber !== action.payload.timeInNumber)
          .concat(action.payload),
        wholesalerDetail: action.payload,
      };
    case "DELETE_WHOLESALER":
      return {
        ...state,
        wholesaler: state.wholesaler.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default wholesalerReducer;
