import React from "react";
import Page404Component from "../component/Page404/Page404";

const Page404 = () => {
  return (
    <div>
      <Page404Component />
    </div>
  );
};

export default Page404;
