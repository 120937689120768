import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import englishReducer from "./reducer/englishReducer";
import userReducer from "./reducer/userReducer";
import historyReducer from "./reducer/historyReducer";
import sidebarReducer from "./reducer/sidebarReducer";
import indonesiaReducer from "./reducer/indonesiaReducer";
import promoReducer from "./reducer/promoReducer";
import profileReducer from "./reducer/profileReducer";
import newsletterReducer from "./reducer/newsletterReducer";
import resellerReducer from "./reducer/resellerReducer";
import corporateReducer from "./reducer/corporateReducer";
import petraReducer from "./reducer/petraReducer";
import wholesalerReducer from "./reducer/wholesalerReducer";
import supplierReducer from "./reducer/supplierReducer";
import favoritePartnerReducer from "./reducer/favoritePartnerReducer";
import visitorHistoryReducer from "./reducer/visitorHistoryReducer";
import faqIndonesiaReducer from "./reducer/faqIndonesiaReducer";
import faqEnglishReducer from "./reducer/faqEnglishReducer";
import guidanceEnglishReducer from "./reducer/guidanceEnglishReducer";
import guidanceIndonesiaReducer from "./reducer/guidanceIndonesiaReducer";
const reducers = combineReducers({
  englishReducer,
  userReducer,
  historyReducer,
  sidebarReducer,
  indonesiaReducer,
  profileReducer,
  promoReducer,
  newsletterReducer,
  resellerReducer,
  corporateReducer,
  petraReducer,
  wholesalerReducer,
  supplierReducer,
  favoritePartnerReducer,
  visitorHistoryReducer,
  faqEnglishReducer,
  faqIndonesiaReducer,
  guidanceEnglishReducer,
  guidanceIndonesiaReducer,
});
const middlewares = applyMiddleware(thunk);

const store = createStore(reducers, middlewares);

export default store;
