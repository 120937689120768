const initialState = { petra: [], petraDetail: {} };

function petraReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PETRA":
      return {
        ...state,
        petra: action.payload,
      };
    case "SET_ONE_PETRA":
      return {
        ...state,
        petraDetail: action.payload,
      };
    case "ADD_PETRA":
      return {
        ...state,
        petra: state.petra.concat(action.payload),
      };
    case "UPDATE_PETRA":
      return {
        ...state,
        petra: state.petra
          .filter((data) => data.timeInNumber !== action.payload.timeInNumber)
          .concat(action.payload),
        petraDetail: action.payload,
      };
    case "DELETE_PETRA":
      return {
        ...state,
        petra: state.petra.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default petraReducer;
