const initialState = { corporate: [], corporateDetail: {} };

function corporateReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CORPORATE":
      return {
        ...state,
        corporate: action.payload,
      };
    case "SET_ONE_CORPORATE":
      return {
        ...state,
        corporateDetail: action.payload,
      };
    case "ADD_CORPORATE":
      return {
        ...state,
        corporate: state.corporate.concat(action.payload),
      };
    case "UPDATE_CORPORATE":
      return {
        ...state,
        corporate: state.corporate
          .filter((data) => data.timeInNumber !== action.payload.timeInNumber)
          .concat(action.payload),
        corporateDetail: action.payload,
      };
    case "DELETE_CORPORATE":
      return {
        ...state,
        corporate: state.corporate.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default corporateReducer;
