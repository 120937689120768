const initialState = { faqEnglish: [], faqEnglishDetail: {} };

function faqEnglishReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_FAQ_ENGLISH":
      return {
        ...state,
        faqEnglish: action.payload,
      };
    case "SET_ONE_FAQ_ENGLISH":
      return {
        ...state,
        faqEnglishDetail: action.payload,
      };
    case "ADD_FAQ_ENGLISH":
      return {
        ...state,
        faqEnglish: state.faqEnglish.concat(action.payload),
      };
    case "UPDATE_FAQ_ENGLISH":
      return {
        ...state,
        faqEnglish: state.faqEnglish
          .filter((data) => data.timeInNumber !== action.payload.timeInNumber)
          .concat(action.payload),
        faqEnglishDetail: action.payload,
      };
    case "DELETE_FAQ_ENGLISH":
      return {
        ...state,
        faqEnglish: state.faqEnglish.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default faqEnglishReducer;
