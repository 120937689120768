const initialState = { faqIndonesia: [], faqIndonesiaDetail: {} };

function faqIndonesiaReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_FAQ_INDONESIA":
      return {
        ...state,
        faqIndonesia: action.payload,
      };
    case "SET_ONE_FAQ_INDONESIA":
      return {
        ...state,
        faqIndonesiaDetail: action.payload,
      };
    case "ADD_FAQ_INDONESIA":
      return {
        ...state,
        faqIndonesia: state.faqIndonesia.concat(action.payload),
      };
    case "UPDATE_FAQ_INDONESIA":
      return {
        ...state,
        faqIndonesia: state.faqIndonesia
          .filter((data) => data.timeInNumber !== action.payload.timeInNumber)
          .concat(action.payload),
        faqIndonesiaDetail: action.payload,
      };
    case "DELETE_FAQ_INDONESIA":
      return {
        ...state,
        faqIndonesia: state.faqIndonesia.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default faqIndonesiaReducer;
