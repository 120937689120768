const initialState = {
  indonesia: [],
  indonesiaUpdate: {},
  indonesiaForm: {},
};

function indonesiaReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_INDONESIA":
      return {
        ...state,
        indonesia: action.payload,
      };
    case "UPDATE_INDONESIA":
      return {
        ...state,
        indonesiaForm: action.payload,
      };
    case "SET_INDONESIA_UPDATE":
      return {
        ...state,
        indonesiaUpdate: action.payload,
      };

    case "SET_FORM_INDONESIA":
      return {
        ...state,
        indonesiaForm: action.payload,
      };
    default:
      return state;
  }
}

export default indonesiaReducer;
