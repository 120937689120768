const initialState = { guidanceEnglish: [], guidanceEnglishDetail: {} };

function guidanceEnglishReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_GUIDANCE_ENGLISH":
      return {
        ...state,
        guidanceEnglish: action.payload,
      };
    case "SET_ONE_GUIDANCE_ENGLISH":
      return {
        ...state,
        guidanceEnglishDetail: action.payload,
      };
    case "ADD_GUIDANCE_ENGLISH":
      return {
        ...state,
        guidanceEnglish: state.guidanceEnglish.concat(action.payload),
      };
    case "UPDATE_GUIDANCE_ENGLISH":
      for (let i = 0; i < state.guidanceEnglish.length; i++) {
        if (
          state.guidanceEnglish[i].timeInNumber === action.payload.timeInNumber
        ) {
          state.guidanceEnglish[i] = action.payload;
        }
      }
      return {
        ...state,
        guidanceEnglish: state.guidanceEnglish,
      };
    case "DELETE_GUIDANCE_ENGLISH":
      return {
        ...state,
        guidanceEnglish: state.guidanceEnglish.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default guidanceEnglishReducer;
