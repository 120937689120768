const initialState = {
  guidanceIndonesia: [],
  guidanceIndonesiaDetail: {},
};

function guidanceIndonesiaReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_GUIDANCE_INDONESIA":
      return {
        ...state,
        guidanceIndonesia: action.payload,
      };
    case "SET_ONE_GUIDANCE_INDONESIA":
      return {
        ...state,
        guidanceIndonesiaDetail: action.payload,
      };
    case "ADD_GUIDANCE_INDONESIA":
      return {
        ...state,
        guidanceIndonesia: state.guidanceIndonesia.concat(action.payload),
      };
    case "UPDATE_GUIDANCE_INDONESIA":
      for (let i = 0; i < state.guidanceIndonesia.length; i++) {
        if (
          state.guidanceIndonesia[i].timeInNumber ===
          action.payload.timeInNumber
        ) {
          state.guidanceIndonesia[i] = action.payload;
        }
      }
      return {
        ...state,
        guidanceIndonesia: state.guidanceIndonesia,
      };
    case "DELETE_GUIDANCE_INDONESIA":
      return {
        ...state,
        guidanceIndonesia: state.guidanceIndonesia.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default guidanceIndonesiaReducer;
