const initialState = { favoritePartner: [] };

function favoritePartnerReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_FAVORITEPARTNER":
      return {
        ...state,
        favoritePartner: action.payload,
      };
    case "ADD_FAVORITEPARTNER":
      return {
        ...state,
        favoritePartner: state.favoritePartner.concat(action.payload),
      };
    case "UPDATE_FAVORITEPARTNER":
      return {
        ...state,
        favoritePartner: state.favoritePartner
          .filter((data) => data.idPartner !== action.payload.idPartner)
          .concat(action.payload),
      };
    case "DELETE_FAVORITEPARTNER":
      return {
        ...state,
        favoritePartner: state.favoritePartner.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    case "DELETE_FAVORITEPARTNER_BYIDPARTNER":
      return {
        ...state,
        favoritePartner: state.favoritePartner.filter(
          (data) => data.idPartner !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default favoritePartnerReducer;
