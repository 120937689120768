const initialState = { profile: [], profileDetail: {} };

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PROFILE":
      return {
        ...state,
        profile: action.payload,
      };
    case "SET_ONE_PROFILE":
      return {
        ...state,
        profileDetail: action.payload,
      };
    case "ADD_PROFILE":
      return {
        ...state,
        profile: state.profile.concat(action.payload),
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        profile: state.profile
          .filter((data) => data.timeInNumber !== action.payload.timeInNumber)
          .concat(action.payload),
        profileDetail: action.payload,
      };
    case "DELETE_PROFILE":
      return {
        ...state,
        profile: state.profile.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default profileReducer;
