const initialState = { supplier: [], supplierDetail: {} };

function supplierReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_SUPPLIER":
      return {
        ...state,
        supplier: action.payload,
      };
    case "SET_ONE_SUPPLIER":
      return {
        ...state,
        supplierDetail: action.payload,
      };
    case "ADD_SUPPLIER":
      return {
        ...state,
        supplier: state.supplier.concat(action.payload),
      };
    case "UPDATE_SUPPLIER":
      return {
        ...state,
        supplier: state.supplier
          .filter((data) => data.timeInNumber !== action.payload.timeInNumber)
          .concat(action.payload),
        supplierDetail: action.payload,
      };
    case "DELETE_SUPPLIER":
      return {
        ...state,
        supplier: state.supplier.filter(
          (data) => data.timeInNumber !== action.payload
        ),
      };
    default:
      return state;
  }
}

export default supplierReducer;
